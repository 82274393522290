import { SERVICE_AUTH } from "@/services";
import { CONSTANTS } from "@/utils";
import { TYPES_AUTH } from "./auth.types";

const login = (data, callback) => {
	return (dispatch) => {
		dispatch({ type: TYPES_AUTH.AUTH_LOGIN_LOADING, isLoading: true });
		SERVICE_AUTH.login(data, (res) => {
            dispatch({
                type: TYPES_AUTH.AUTH_LOGIN,
                payload: res.payload,
                error: res.error,
            });
			if (res.success && callback) {
				localStorage.setItem(CONSTANTS.AUTH_TOKEN, res.payload.token);
				callback(res.payload);
			}
		});
	};
};

export const ACTION_AUTH = {
	login,
};
