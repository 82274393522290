const baseUrl = import.meta.env.VITE_API_BASE_URL;
const login = (data, callback) => {
	axios
		.post("/login", data, {
			baseURL: baseUrl.replace("/api", ""),
		})
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_AUTH = {
	login,
};
